const coins = [
    {
        id: 1,
        name: "btc",
        preffix: "BTC",
        image: "btc.png",
        network: 'BSC',
        min: "0.00000100",
        active: true
    },
];

export default coins;